$primaryColor: #ffd54f;
$primaryLightColor: scale-color($primaryColor, $lightness: 30%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #212529;

$highlightBg: $primaryColor;
$highlightTextColor: $primaryTextColor;

$tableHeaderCellPadding: 0.5rem 0.5rem;
$tableBodyCellPadding: 0.5rem 0.5rem;
$tableFooterCellPadding: 0.5rem 0.5rem;

$focusShadow: none;

@import '../_variables';
