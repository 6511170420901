$livesubHeaderHeight: 60px !default;
$livesubFooterHeight: 32px !default;

.livesub-sidebar {
  min-height: calc(100vh - #{$livesubHeaderHeight});
  max-height: calc(100vh - #{$livesubHeaderHeight});
  overflow-y: auto;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: var(--surface-d);
  background-color: var(--surface-c);
}

.livesub-navigation {
  background-color: var(--surface-c);
  box-sizing: border-box;
  height: #{$livesubHeaderHeight};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--surface-d);

  h1 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.5em;
    line-height: 1.8em;
  }
}

.livesub-main {
  min-height: calc(100vh - #{$livesubHeaderHeight});
  max-height: calc(100vh - #{$livesubHeaderHeight});
  overflow-y: auto;
  flex: 1;
}

.livesub-footer {
  background-color: var(--surface-c);
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--surface-d);
  height: $livesubFooterHeight;
  min-height: $livesubFooterHeight;

  p {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.0em;
    line-height: 1.0em;
    font-weight: normal;

    color: #a8a8a8;

    & > a {
      color: #a8a8a8;
      text-decoration: underline;
    }
  }
}

.livesub-main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.livesub-textarea {
  height: 5em;
  min-height: 5em;
  resize: vertical;
}

video {
  outline: 0 none;
}

.p-datatable {
  .p-datatable-tbody {
    > tr {
      &:focus {
        outline: 0 none;
        border-bottom-style: solid;
        border-bottom-color: white;
        border-bottom-width: 2px;
      }
    }
  }
}
