/* You can add global styles to this file, and also import other style files */

html {
  font-size: 16px;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-a);
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;

  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  line-height: 1.5;
}

@import './sass/themes/vela/vela-livesub/_variables';
@import './sass/livesub';

.ace_editor {
  // background-color: $inputBg;
}

.ace_print-margin {
  background: $inputBg;
}

.ace_invisible {
  color: $primaryDarkColor !important;
}

.settings-dialog.p-dialog {
  width: 40vw;

  @media screen and (max-width: 1200px) {
    width: 50vw;
  }
}
